<template>
    <div v-loading="dataLoading">
        <Edit v-if="reFresh && formData.status != 1" :formData.sync="formData" @onUpdate="getDataDetail"></Edit>
        <ViewDetail v-if="reFresh && formData.status == 1" :formData.sync="formData" @onUpdate="getDataDetail"></ViewDetail>
    </div>
</template>

<script>
import Edit from './components/edit';
import ViewDetail from './components/view';
const defaultFormData = {
    accountIdName: "",
	accountPic: "",
	accountType: 1,
	address: "",
	bankAccount: "",
	bankAddress: "",
	bankCardId: "",
	bankId: 1,
	otherPic: "",
	userEmail: "",
	userID: "",
	userName: "",
    userTelephone: "",
    status:0,
    pic1:'',
    pic2:'',
    bankCityCode: '',
    bankProvinceCode: '',
	//businessType:0,
	//registeredAddress:'',
	//registeredCapitalStr:'',
	corporationName:'',
	bankType:0,
	bankName:''
}
export default {
    components:{Edit,ViewDetail},
    data(){
        return{
            dataLoading: false,
            formData:{...defaultFormData},
            reFresh: false,
        }
    },
    mounted(){
        this.getDataDetail();
    },
    methods:{
        //获取详情
        getDataDetail() {
            this.dataLoading = true;
            this.$request({
                url: "/activity/receivables/getInfo",
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        const _accountPic = result.accountPic ? result.accountPic.split(',') : [];
                        this.formData = {
                            ...this.formData,
                            ...result,
                            pic1: _accountPic[0] || '',
                            pic2: _accountPic[1] || '', 
                        }
                    } else {
                        // this.$message.error(msg || "获取信息失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取信息失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                    this.reFresh = true;
                });
        },
    }
}
</script>