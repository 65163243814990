<template>
    <div>
        <el-steps simple :active="formData.sendStatus - 1" finish-status="success">
            <el-step title="审核收款信息"><span slot="icon">1</span></el-step>
            <el-step title="签约收款合同"><span slot="icon">2</span></el-step>
            <el-step title="审核收款合同"><span slot="icon">3</span></el-step>
        </el-steps>
        <div class="page-group-box data-tips data-status">
            <span class="icon el-icon-warning color-warning"></span> 
            <template v-if="formData.sendStatus == 1">
                您已提交演出收款信息，<span class="color-danger">我们会尽快审核，并发送电子合同到您的收款联系人邮箱，请耐心等待</span>
            </template>
            <template v-if="formData.sendStatus == 2">
                电子合同已发出，请到收款联系人邮箱中查收，<span class="color-danger">注意邮件可能被自动放入垃圾箱</span>
            </template>
            <template v-if="formData.sendStatus == 3">
                合同已寄回，<span class="color-danger">请耐心等待我们接收您寄出的合同，收到合同后，我们会在5个工作日内进行处理</span>
            </template>
        </div>
        <div v-if="formData.sendStatus == 2" class="page-group-box">
            <div class="data-status">
                <span class="icon el-icon-warning color-warning"></span> 
                如对合同无异议，请您打印两份后签字盖章寄回我处
            </div>
            <div class="color-info MT10">
                <p>请在下面提交寄回时选用的快递公司和运单号，收件地址：北京市朝阳区三里屯西五街五号院C座一层，010-64686576</p>
                <p class="MT5">我们将在收到您寄出的合同后，5个工作日内进行处理</p>
            </div>
            <div class="MT20">
                <div class="page-group-title">邮寄信息确认</div>
                <el-form
                    :model="formData"
                    ref="formData"
                    label-position="top"
                >
                    <el-row :gutter="24">
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item prop="mailCompany" label="快递公司:" :rules="[{required: true, message: '请输入快递公司'}]">
                                <el-input v-model="formData.mailCompany" placeholder="请输入快递公司" size="medium" maxlength="40"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item prop="mailNo" label="运单号:" :rules="[{required: true, message: '请输入运单号'}]">
                                <el-input v-model="formData.mailNo" placeholder="请输入运单号" size="medium" maxlength="100"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm" :loading="btnLoading" size="medium">确定合同已寄出</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div v-if="formData.sendStatus == 3" class="page-group-box">
            <div class="page-group-title">邮寄信息</div>
            <el-row class="MT20">
                <el-col :xs="24" :xl="16">
                    <table class="info-table" width="100%">
                        <tr>
                            <th>快递公司</th>
                            <td>{{formData.mailCompany}}</td>
                            <th>运单号</th>
                            <td>{{formData.mailNo}}</td>
                        </tr>
                        <tr>
                            <th>寄回时间</th>
                            <td colspan="3">{{formData.backTime}}</td>
                        </tr>
                    </table>
                </el-col>
            </el-row>
        </div>
        <div class="page-group-box" :class="formData.sendStatus != 1 ? 'MT10' : ''">
            <div class="page-group-title">收款信息</div>
            <div class="data-status MT20">当前状态:待审核,当前无法修改收款信息,如有任何疑问请联系秀动客服 400-188-6980</div>
            <el-row class="MT20">
                <el-col :xs="24" :xl="16">
                    <table class="info-table" width="100%">
                        <tr>
                            <th>收款联系人姓名</th>
                            <td>{{formData.userName}}</td>
                            <th>银行</th>
                            <td>
								<span v-if="formData.bankType == 0">{{formData.bankName}}</span>
								<span v-if="formData.bankType == 1">中国大陆以外</span>
							</td>
                        </tr>
                        <tr>
                            <th>收款人联系手机</th>
                            <td>{{formData.userTelephone}}</td>
                            <th v-if="formData.bankType == 0">开户网点</th>
                            <td v-if="formData.bankType == 0">{{formData.bankAddress}}</td>
							<th v-if="formData.bankType == 1">银行名称</th>
							<td v-if="formData.bankType == 1">{{formData.bankAddress}}</td>
                        </tr>
                        <tr>
                            <th>收款人联系邮箱</th>
                            <td>{{formData.userEmail}}</td>
                            <th><span v-if="formData.bankType == 0">卡号</span><span v-else>收款银行代码</span></th>
                            <td>{{formData.bankCardId}}</td>
                        </tr>
                        <tr>
                            <th>通讯地址</th>
                            <td>{{formData.address}}</td>
                            <th> 账户名</th>
                            <td>{{formData.bankAccount}}</td>
                        </tr>
                        <tr>
                            <th>账户性质</th>
                            <td>{{formData.accountType == 1 ? '企业' : '个人'}}</td>
							<th>企业类型</th>
							<td>{{formData.businessType == 0 ? '有限责任公司（自然人投资或控股）' : '股份有限公司'}}</td>
                        </tr>
						<tr>
						    <th>注册资本</th>
						    <td>{{formData.registeredCapitalStr}}万元</td>
							<th>法人代表名称</th>
							<td>{{formData.corporationName}}</td>
						</tr>
						<tr>
						    <th>注册地址</th>
						    <td colspan="3">{{formData.registeredAddress}}</td>   
						</tr>
                        <tr v-if="formData.accountType == 1">
                            <th>营业执照编号</th>
                            <td colspan="3">{{formData.userID}}</td>   
                        </tr>
                        <tr v-else>
                            <th>证件类型</th>
                            <td>{{formData.accountIdName}}</td>
                            <th>证件号码</th>
                            <td>{{formData.userID}}</td>
                        </tr>
                        <tr>
                            <th>{{formData.accountType == 1 ? '营业执照' : '证件'}}</th>
                            <td colspan="3">
                                <div v-if="formData.accountPic" class="img">
                                    <el-image v-for="(item,index) in formData.accountPic.split(',')" :key="index" :src="item" fit="cover" :preview-src-list="formData.accountPic.split(',')"></el-image>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="formData.accountType == 1">
                            <th>开户许可证/<br/>基本存款账户信息</th>
                            <td colspan="3">
                                <div v-if="formData.otherPic" class="img">
                                    <el-image v-for="(item,index) in formData.otherPic.split(',')" :key="index" :src="item" fit="cover" :preview-src-list="formData.otherPic.split(',')"></el-image>
                                </div>
                            </td>
                        </tr>
                    </table>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        formData:{
            type: Object,
            default: null
        }
    },
    data(){
        return{
            btnLoading:false,
        }
    },
    methods:{
        //表单提交
        submitForm() {
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    const _url = "/activity/receivables/sendBack";
                    this.btnLoading = true;
                    this.$request({
                        url: _url,
                        params: {mailCompany:this.formData.mailCompany,mailNo:this.formData.mailNo},
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$message.success('提交成功');
                                this.$emit('onUpdate');
                            } else {
                                this.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }else {
                    return false;
                }   
            });
        },
    },
}
</script>


<style lang="scss" scoped>
.el-steps{
    margin-bottom: 10px;
    background: #EBEEF5;
    ::v-deep .el-step__head.is-success,
    ::v-deep .el-step__title.is-success{
        color: $--color-theme;
        border-color:  $--color-theme;
    }
}
.data-tips{
    line-height: 32px;
    padding: 12px 20px;
    margin-bottom: 10px;
}
.icon{
    font-size: 14px;
}
.data-status{
    font-weight: 500;
}
.img{
    .el-image{
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }
}
th{
    width: 130px;
}
</style>